import React, {useState} from 'react';
import '../../../assets/css/AnalisisDinamico.css';
import foto1 from "../../../assets/images/mantenimiento/analisisDinamico/1_analisis_dinamico.png";
import foto2 from "../../../assets/images/mantenimiento/analisisDinamico/2_analisis_dinamico.png";
import foto3 from "../../../assets/images/mantenimiento/analisisDinamico/3_analisis_dinamico.png";
import foto4 from "../../../assets/images/mantenimiento/analisisDinamico/4_analisis_dinamico.png";
import LightBox from "react-images";
import FormMantenimiento from "./FormMantenimiento";

export default function AnalisisDinamico() {
    let [currentImage, setCurrentImage] = useState(0);
    let [lightBoxIsOpen, setLightBoxIsOpen] = useState(false);
    let [currentPage, setCurrentPage] = useState(0);
    let [servicio, setServicio] = useState({nombre: "Análisis Dinámico"});
    const [showModalMantenimiento, setShowModalMantenimiento] = useState(false);

    function openLightbox(event, obj) {
        setCurrentImage(obj.index);
        setLightBoxIsOpen(true);
    }

    function closeLightbox() {
        setCurrentImage(0);
        setLightBoxIsOpen(false);
    }

    function gotoPreviousImg() {
        setCurrentImage(currentImage - 1);
    }

    function gotoNextImg() {
        setCurrentImage(currentImage + 1);
    }

    function openModalMantenimiento() {
        setShowModalMantenimiento(true);
    }

    function closeModalMantenimiento() {
        setShowModalMantenimiento(false);
    }

    return (
        <div id="analisisDinamico">
            <FormMantenimiento servicio={servicio} hidden={showModalMantenimiento}
                               closeModal={closeModalMantenimiento}/>
            <section className="analisisDinamico analisis">
                <div className="container-limit">
                    <div className="container-titulo">
                        <h3><b>ANÁLISIS DINÁMICO</b>&nbsp;DE MÁQUINAS ELÉCTRICAS</h3>
                    </div>
                    <div className="container-doble">
                        <div className="col-6">
                            <div className="container-text">
                                <p>
                                    Es sabido que la condición fundamental para el buen desempeño de un motor eléctrico
                                    a lo largo del tiempo es la garantía de una calidad de energía correcta, cuyos
                                    parámetros eléctricos se mantengan estables a lo largo del tiempo y bajo cualquier
                                    régimen de carga.
                                </p>
                                <b>
                                    Para la correcta verificación del funcionamiento del motor a analizar, se relevan
                                    las siguientes lecturas y sus variaciones:
                                </b>
                                <ul>
                                    <li>
                                        Oscilograma de tensión y corriente
                                    </li>
                                    <li>
                                        Evolución de tensión, corriente y frecuencia
                                    </li>
                                    <li>
                                        Fluctuaciones
                                    </li>
                                    <li>
                                        Armónicos
                                    </li>
                                    <li>
                                        Potencia y energía
                                    </li>
                                    <li>
                                        Eficiencia de inversores
                                    </li>
                                    <li>
                                        Desequilibrios
                                    </li>
                                    <li>
                                        Corrientes de arranque
                                    </li>
                                    <li>
                                        Transitorios
                                    </li>
                                    <li>
                                        Lectura de variables mecánicas de motores
                                    </li>
                                </ul>
                                <div className="analisis-btn-container">
                                    <button onClick={() => {
                                        window.open(`${process.env.PUBLIC_URL}/pdf/Analisis dinamico.pdf`, '_blank')
                                    }}>
                                        DESCARGAR PDF
                                    </button>
                                    <button onClick={openModalMantenimiento}>
                                        REALICE SU CONSULTA
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="container-text">
                                <p>
                                    En consecuencia, Fabrifor ofrece a sus clientes un servicio de análisis dinámico de
                                    calidad de energía y verificación de parámetros electromecánicos que el motor está
                                    entregando en el eje durante su funcionamiento.
                                </p>
                                <div className="galeria-img">
                                    <div className="img-principal"
                                         onClick={(e) => openLightbox(e, {index: 0})}
                                    >
                                        <img src={foto1} alt=""/>
                                    </div>

                                    <div className="img-thumb">
                                        <div className="thumb"
                                             onClick={(e) => openLightbox(e, {index: 1})}
                                        >
                                            <img src={foto2} alt=""/>
                                        </div>
                                        <div className="thumb"
                                             onClick={(e) => openLightbox(e, {index: 2})}
                                        >
                                            <img src={foto3} alt=""/>
                                        </div>
                                        <div className="thumb"
                                             onClick={(e) => openLightbox(e, {index: 3})}
                                        >
                                            <img src={foto4} alt=""/>
                                        </div>
                                        <LightBox images={[
                                            {src: foto1},
                                            {src: foto2},
                                            {src: foto3},
                                            {src: foto4}
                                        ]}
                                                  onClose={closeLightbox}
                                                  onClickPrev={gotoPreviousImg}
                                                  onClickNext={gotoNextImg}
                                                  currentImage={currentImage}
                                                  isOpen={lightBoxIsOpen}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}