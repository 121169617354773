import React from 'react';
import "../../../assets/css/Productos.css";
import BotonesCategorias from "./BotonesCategorias";
import {Link} from 'react-router-dom';
//Components
import data from "../../../data/productos";

//Img


class Productos extends React.Component {

    constructor(props) {
        super(props);
        // console.log(props);
        this.state = {
            categSelected: 1,
            subCategSelected: 1,
            productoSelected: 1,
            opcBotones: 1,
        };

        this.changeSubCateg = this.changeSubCateg.bind(this);
    }

    componentDidMount() {
        let idCategoria = this.props.match.params.idCategoria;
        if (idCategoria) {
            this.setState({
                categSelected: idCategoria,
                subCategSelected: null,
                productoSelected: null,
                opcBotones: 1,
            });
        }
    }


    changeSubCateg(idSubCateg) {
        if (idSubCateg == 1) {
            this.setState({
                subCategSelected: null,
                categSelected: 1,
                productoSelected: null,
            });
        } else {
            this.setState({
                subCategSelected: idSubCateg,
                categSelected: null,
                productoSelected: null,
            });
        }
        window.scroll({
            top: 85,
            left: 0,
            behavior: 'smooth'
        });
    }

    toggleMenuCateg() {
        this.refs.menuRes.classList.toggle("open");
        this.refs.btnMenuRes.classList.toggle("open");
    }

    closeMenuCateg(subCategSelected) {
        let menuRes = this.refs.menuRes;
        if (menuRes.classList.contains("open") && subCategSelected != 6 && subCategSelected != 7) {
            menuRes.classList.remove("open");
            // console.log(subCategSelected);
        }
    }

    render() {
        // console.log(this.props);
        const {subCategSelected, categSelected, productoSelected} = this.state;
        let productosItems = [];

        let menu = data.categorias.map((categoria) => {
            let subCategorias = categoria.subCategorias.map((subCategoria) => {
                let productos = [];
                subCategoria.productos.some((producto) => {
                    if ((!productoSelected && subCategoria.id == subCategSelected) || productoSelected == producto.id || (!subCategSelected && categSelected == categoria.id))
                        productosItems.push(
                            <Link to={`/producto/${producto.id}`} style={{textDecoration: "none"}}>
                                <div key={producto.id} className="tarjeta-producto">
                                    <h6>{producto.nombre}</h6>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/productos/img/${producto.folder}/${producto.img[0]}.png`}
                                        alt=""/>
                                </div>
                            </Link>);
                    if (categoria.id != 1)
                        productos.push(
                            <li onClick={() => {
                                this.setState({
                                    subCategSelected: subCategSelected,
                                    categSelected: null,
                                    productoSelected: producto.id
                                });
                                this.closeMenuCateg();
                            }}
                                className={`itemMenu ${productoSelected == producto.id ? "selected" : ""}`}>{producto.nombreCorto}</li>
                        );
                    return false;
                });
                return (
                    <div>
                        <li className={categoria.id != 1 ? "subCateg" + (subCategSelected == subCategoria.id ? " selected" : "") : ("subCategMotores" + (subCategSelected == subCategoria.id ? " selected" : ""))}
                            style={{cursor: "pointer"}}
                            onClick={() => {
                                this.setState({
                                    subCategSelected: subCategoria.id,
                                    categSelected: null,
                                    productoSelected: null,
                                });
                                this.closeMenuCateg(subCategoria.id);
                            }}>{subCategoria.nombre}</li>
                        {productos.length > 0 ?
                            <ul className={`arrancadores ${subCategSelected == subCategoria.id ? "selected" : ""}`}>{productos}</ul> : ""}
                    </div>

                )
            });
            return (
                <div style={{paddingRight: "15px"}}>
                    <h4 onClick={() => {
                        this.setState({
                            subCategSelected: null,
                            categSelected: categoria.id,
                            productoSelected: null
                        });
                        this.closeMenuCateg();
                    }}
                    style={{fontWeight:categSelected == categoria.id ? "500" : "200"}}>{categoria.nombre}</h4>
                    <ul>{subCategorias}</ul>
                </div>
            )
        });

        return (
            <div id={"productos"}>
                <section className="productos">
                    <div ref="menuRes" className="menu-categorias res">
                        {menu}
                    </div>
                    <div className="btn-menu-categorias" ref="btnMenuRes" onClick={() => this.toggleMenuCateg()}>
                        MÁS PRODUCTOS
                    </div>
                    <div className="bg-productos"></div>
                    <div className="container-limit">
                        <div className="container-doble">
                            <div className="col-3 menu-categorias">
                                {menu}
                            </div>
                            <div className="col-9">
                                <div className="container-productos">
                                    <div className="grilla-productos">
                                        {productosItems}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <BotonesCategorias push={this.changeSubCateg}
                                           opc={subCategSelected == 6 ? 3 : (subCategSelected == 7 ? 2 : 1)}
                        />
                    </div>
                </section>
            </div>

        );
    }
}

export default Productos;
