import React, {useState} from 'react';
import Beneficios from "./Beneficios";
import AnalisisEstatico from "./AnalisisEstatico";
import SliderPlantas from "./SliderPlantas";
import Webinar from "./Webinar";
import AnalisisDinamico from "./AnalisisDinamico";
import MonitoreoVibraciones from "./MonitoreoVibraciones";

export default function MantenimientoPredictivo() {


    return (
        <div>
            <Beneficios/>
            <AnalisisEstatico/>
            <SliderPlantas/>
            <Webinar/>
            <AnalisisDinamico/>
            <MonitoreoVibraciones/>
        </div>
    );
}