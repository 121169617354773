import React from 'react';
import "../../../assets/css/HeaderTitulo.css";
//Components


//Img


export default function HeaderTitulo(props) {

    return (
        <div id={"header-titulo"}>
            <section className="header-titulo">
                <div className="container-limit">
                    <h3>{props.titulo}</h3>
                </div>
            </section>
        </div>
    );
}
