import React, {useState} from 'react';
import icon1 from "../../../assets/images/mantenimiento/icon-delIncert.png";
import icon2 from "../../../assets/images/mantenimiento/icon-perdidas.png";
import icon3 from "../../../assets/images/mantenimiento/icon-coordinar.png";
import '../../../assets/css/Beneficios.css';
import HeaderTitulo from "../ElementosLayout/HeaderTitulo";

export default function Beneficios() {


    return (
        <div id="beneficios">
            <HeaderTitulo titulo={<>MANTENIMIENTO<b>&nbsp;PREDICTIVO&nbsp;</b></>}/>
            <section className="beneficios">
                <div className="container-limit">
                    <div className="container-titulo">
                        <h3><b>BENEFICIOS</b></h3>
                    </div>
                    <div className="container-tarjetas">
                        <div className="tarjeta-beneficio">
                            <div className="tarjeta-titulo">
                                <div className="tarjeta-titulo-flex">
                                    <img src={icon1} alt=""/>
                                    <h6>ELIMINACIÓN DE INCERTIDUMBRE</h6>
                                </div>
                            </div>
                            <div className="tarjeta-cuerpo">
                                <p>
                                    Conocer el estado de una máquina eléctrica realizando un seguimiento histórico sirve
                                    para predecir fallas y eliminar la raíz causa
                                </p>
                            </div>
                        </div>
                        <div className="tarjeta-beneficio">
                            <div className="tarjeta-titulo">
                                <div className="tarjeta-titulo-flex">
                                    <img src={icon2} alt=""/>
                                    <h6>EVITAR PÉRDIDAS ECONÓMICAS POR PROCESOS DETENIDOS</h6>
                                </div>
                            </div>
                            <div className="tarjeta-cuerpo">
                                <p>
                                    Si se puede determinar el mal estado de una máquina previo a un defecto, se pueden
                                    coordinar acciones preventivas para evitar problemas sorpresivos.
                                </p>
                            </div>
                        </div>
                        <div className="tarjeta-beneficio">
                            <div className="tarjeta-titulo">
                                <div className="tarjeta-titulo-flex">
                                    <img src={icon3} alt=""/>
                                    <h6>COORDINACIÓN ÓPTIMA DE PARADAS DE PLANTA</h6>
                                </div>
                            </div>
                            <div className="tarjeta-cuerpo">
                                <p>
                                    Seleccionar mediante evaluaciones históricas los motores a los cuales darle
                                    prioridad en una parada de planta.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}