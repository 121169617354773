import React, {useState, useRef, useEffect} from 'react';
import '../../../assets/css/SliderPlantas.css';

import OwlCarousel from 'react-owl-carousel2';
import ItemParquesEolicos from "./Plantas/ItemsParquesEolicos";
import ItemsPlantasIndustriales from "./Plantas/ItemsPlantasIndustriales";
import ItemsControlDeCalidad from "./Plantas/ItemsControlDeCalidad";
import FormMantenimiento from "./FormMantenimiento";
import atras from '../../../assets/images/atras.png';
import adelante from '../../../assets/images/adelante.png';

export default function SliderPlantas(props) {

    let [currentImage, setCurrentImage] = useState(0);
    let [lightBoxIsOpen, setLightBoxIsOpen] = useState(-1);
    let [currentPage, setCurrentPage] = useState(0);
    let [servicio, setServicio] = useState({nombre: "Parques Eólicos"});
    const [showModalMantenimiento, setShowModalMantenimiento] = useState(false);
    const car = useRef();

    function openLightbox(event, obj) {
        setCurrentImage(obj.index);
        setLightBoxIsOpen(obj.lightboxNum);
    }

    function closeLightbox() {
        setCurrentImage(0);
        setLightBoxIsOpen(-1);
    }

    function gotoPreviousImg() {
        setCurrentImage(currentImage - 1);
    }

    function gotoNextImg() {
        setCurrentImage(currentImage + 1);
    }

    function setPage(page) {
        car.current.goTo(page);
    }

    function nextPage() {
        car.current.next();
    }

    function prevPage() {
        car.current.prev();
    }

    const events = {
        onTranslated: (owlCarrousel) => {
            setCurrentPage(owlCarrousel.item.index);
        }
    };

    function openModalMantenimiento() {
        setShowModalMantenimiento(true);
    }

    function closeModalMantenimiento() {
        setShowModalMantenimiento(false);
    }

    return (
        <div id="beneficios">
            <FormMantenimiento servicio={servicio} hidden={showModalMantenimiento}
                               closeModal={closeModalMantenimiento}/>
            <section className="slider-plantas">
                <div className="container-nav-car">
                    <img src={atras} onClick={prevPage}/>
                    <img src={adelante} onClick={nextPage}/>
                </div>
                <div className="container-limit">
                    <nav className="nav-plantas">
                        <ul>
                            <li>
                                <p className={currentPage === 0 ? "selected" : ""}
                                   onClick={(e) => {
                                       setPage(0);
                                   }}
                                >
                                    PARQUES EÓLICOS
                                </p>
                            </li>
                            <li>
                                <p className={currentPage === 1 ? "selected" : ""}
                                   onClick={(e) => {
                                       setPage(1);
                                   }}
                                >
                                    PLANTAS INDUSTRIALES
                                </p>
                            </li>
                            <li>
                                <p className={currentPage === 2 ? "selected" : ""}
                                   onClick={(e) => {
                                       setPage(2);
                                   }}
                                >
                                    CONTROL DE CALIDAD
                                </p>
                            </li>
                        </ul>
                    </nav>
                    <OwlCarousel ref={car}
                                 events={events}
                                 options={{
                                     items: 1,
                                     nav: false,
                                     rewind: true,
                                 }}
                    >
                        <div className="item">
                            <ItemParquesEolicos onClick={openLightbox}
                                                onClose={closeLightbox}
                                                onClickPrev={gotoPreviousImg} onClickNext={gotoNextImg}
                                                currentImage={currentImage} open={lightBoxIsOpen === 1}
                                                openModalMantenimiento={() => {
                                                    openModalMantenimiento()
                                                    setServicio({nombre: "Parques Eólicos"});
                                                }
                                                }/>
                        </div>
                        <div className="item">
                            <ItemsPlantasIndustriales onClick={openLightbox}
                                                      onClose={closeLightbox}
                                                      onClickPrev={gotoPreviousImg} onClickNext={gotoNextImg}
                                                      currentImage={currentImage} open={lightBoxIsOpen === 2}
                                                      openModalMantenimiento={() => {
                                                          openModalMantenimiento()
                                                          setServicio({nombre: "Plantas industriales"});
                                                      }}
                            />
                        </div>
                        <div className="item">
                            <ItemsControlDeCalidad onClick={openLightbox}
                                                   onClose={closeLightbox}
                                                   onClickPrev={gotoPreviousImg} onClickNext={gotoNextImg}
                                                   currentImage={currentImage} open={lightBoxIsOpen === 3}
                                                   openModalMantenimiento={() => {
                                                       openModalMantenimiento()
                                                       setServicio({nombre: "Control de calidad"});
                                                   }}
                            />
                        </div>
                    </OwlCarousel>
                </div>
            </section>
        </div>
    );
}