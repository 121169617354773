import React, {Component} from 'react';

//CSS
import './NavSocial.css';
import whatsapp from './wp.png';



class NavSocial extends Component {
    constructor(props){
        super(props);
    }


    render() {

        return (
            <div>
                <div className="icon-container">
                    <a alt="Hablá con nosotros" href="https://api.whatsapp.com/send?phone=5493416537014" target="_blank">
                        <img className="img-responsive" src={whatsapp} alt=""/>
                    </a>
                </div>
            </div>
        );
    }
}


export default NavSocial;
