import React from 'react';


//Components
import Productos from "./Catalogo/Productos";
import HeaderTitulo from "./ElementosLayout/HeaderTitulo";


class Catalogo extends React.Component {
    render() {
        return (
            <div>
                <HeaderTitulo titulo={<>NUESTROS<b>&nbsp;PRODUCTOS&nbsp;</b></>}/>
                <Productos {...this.props}/>
            </div>
        );
    }
}

export default Catalogo;
