import React from 'react';
import "../../../assets/css/Clientes.css";
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/src/owl.carousel.css';

//Components


//Img
import cliente1 from "../../../assets/images/clientes/cliente1.png";
import cliente2 from "../../../assets/images/clientes/cliente2.png";
import cliente3 from "../../../assets/images/clientes/cliente3.png";
import cliente4 from "../../../assets/images/clientes/cliente4.png";
import cliente5 from "../../../assets/images/clientes/cliente5.png";
import cliente6 from "../../../assets/images/clientes/cliente6.png";
import cliente7 from "../../../assets/images/clientes/cliente7.png";
import cliente8 from "../../../assets/images/clientes/cliente8.png";


class Clientes extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    componentWillUnmount() {

    }

    render() {
        return (
            <div>
                <section className="clientes">
                    <div className="contaier-limit">
                        <h3>CONFIAN EN<b>&nbsp;NOSOTROS&nbsp;</b></h3>
                    </div>
                    <div className="container-limit">
                        <OwlCarousel ref="car" options={{
                            items: 4,
                            nav: false,
                            rewind: true,
                            autoplay: false,
                            dots: true,
                            responsive: {
                                // breakpoint from 480 up
                                280: {
                                    items: 2
                                },
                                320: {
                                    items: 2
                                },
                                480: {
                                    items: 2
                                },
                                768: {
                                    items: 4
                                }
                            }
                        }}
                        >
                            <div className="item">
                                <div className="item-flex">
                                    <img src={cliente1} alt=""/>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-flex">
                                    <img src={cliente2} alt=""/></div>

                            </div>
                            <div className="item">
                                <div className="item-flex">
                                    <img src={cliente3} alt=""/>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-flex">
                                    <img src={cliente4} alt=""/>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-flex">
                                    <img src={cliente5} alt=""/>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-flex">
                                    <img src={cliente6} alt=""/>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-flex">
                                    <img src={cliente7} alt=""/>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-flex">
                                    <img src={cliente8} alt=""/>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </section>
            </div>
        );
    }
}

export default Clientes;
