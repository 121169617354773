import React from 'react';
import "../../../assets/css/Footer.css";

//Components


//Img
import tecnologia from "../../../assets/images/icon-tecnologia.png";
import instalaciones from "../../../assets/images/icon-instalaciones.png";


class Footer extends React.Component {
    render() {
        return (
            <footer className="footer">
                    <div className="container-limit">
                        <div className="row">
                            <div className="copyright">
                                <p className="copy">
                                    © 2021 Fabrifor, Todos los derechos reservados.
                                </p>
                            </div>
                            <div className="firma">
                                <a className="desa" target="_blank" href="http://www.exadec.com">
                                    <span><div className="exadec"></div></span>
                                    <p style={{color:"grey",fontSize: "7px", margin: "0"}}>Diseño y desarrollo</p>
                                </a>
                            </div>
                        </div>
                    </div>
            </footer>

        );
    }
}

export default Footer;
