import React from 'react';
import "../../../assets/css/Nav.css";
import {NavHashLink as NavLink} from 'react-router-hash-link'
import {ReactComponent as Arrow} from "../../../assets/images/right-arrow.svg";

//Components


//Img
import {ReactComponent as Logo} from '../../../assets/images/fabrifor-logo.svg';
import history from "../../../history";

class Nav extends React.Component {

    constructor(props) {
        super(props);
        this.itemsMenu = [
            {link: "/", nombre: "HOME"},
            {link: "/#servicios", nombre: "SERVICIOS"},
            {link: "/#sobreNosotros", nombre: "SOBRE NOSOTROS"},
            {link: "/mantenimientoPredictivo", nombre: "MANTENIMIENTO PREDICTIVO"},
            {link: "/productos", nombre: "PRODUCTOS"},
            {link: "/#contacto", nombre: "CONTACTO"}
        ];
    }

    componentDidMount() {
        window.addEventListener('click', (e) => this.closeMenuAnyWhere(e));
    }

    closeMenuAnyWhere(e) {
        let menuRes = this.refs.menuRes;
        let burguer = this.refs.burguerBtn;
        if (burguer && menuRes && !(burguer.contains(e.target) || menuRes.contains(e.target))) {
            menuRes.classList.remove("show");
        }
    }

    toggleMenu() {
        let menuRes = this.refs.menuRes;
        if (menuRes.classList.contains("show")) {
            menuRes.classList.remove("show");
        } else {
            menuRes.classList.add("show");
        }
    }


    closeMenu(){
        let menuRes = this.refs.menuRes;
        if (menuRes.classList.contains("show")) {
            menuRes.classList.remove("show");
        }
    }

    goToHome(){
        history.push("/");
    }


    render() {
        let hash = "/" + window.location.hash;
        if(hash == "/"){
            hash = window.location.pathname;
        }

        let menu = this.itemsMenu.map((item, index) => {
            return (
                <NavLink onClick={() => this.closeMenu()} key={index} smooth scroll={e => e.scrollIntoView({
                    behavior: "smooth",
                })} to={item.link}
                         className={hash == item.link || (item.nombre == "HOME" && hash == "/") ? "act" : ""}>{item.nombre}</NavLink>
            )
        });

        return (
            <div>
                <section className="nav-bar">
                    <div className="container-limit">
                        <div className="container-nav">
                            <div className="logo">
                                <Logo style={{width: "100%", maxWidth: "230px", marginRight: "25px", cursor:"pointer"}} onClick={()=>this.goToHome()}/>
                            </div>
                            <div className="list-nav">
                                {menu}
                            </div>
                            <div id="burguer-menu" ref="burguerBtn" className="burguer-menu"
                                 onClick={() => this.toggleMenu()}>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </section>
                <div ref="menuRes" className="menu-res">
                    <div className="container-menu-res">
                        <div className="titulo-menu-res">
                            <h6>MENU</h6>
                            <Arrow style={{
                                stroke: "white",
                                strokeWidth: "10px",
                                fill: "white",
                                width: "20px",
                                maxHeight: "38px"
                            }} onClick={() => this.toggleMenu()}/>
                        </div>
                        <div className="list-nav-res">
                            {menu}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Nav;
