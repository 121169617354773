import React, {useState} from 'react';

import foto1 from '../../../../assets/images/mantenimiento/plantas/parquesEolicos/1_parque_eolicos.png';
import foto2 from '../../../../assets/images/mantenimiento/plantas/parquesEolicos/2_parque_eolicos.png';
import foto3 from '../../../../assets/images/mantenimiento/plantas/parquesEolicos/3_parque_eolicos.png';
import foto4 from '../../../../assets/images/mantenimiento/plantas/parquesEolicos/4_parque_eolicos.png';
import LightBox from "react-images";
import FormMantenimiento from "../FormMantenimiento";


export default function ItemParquesEolicos(props) {
    const descargarPDF = () => {
        window.open(`${process.env.PUBLIC_URL}/pdf/Diagnostico integral de aislamiento.pdf`, '_blank')
    }

    return <div className="container-limit">
        <div className="container-doble">
            <div className="col-6">
                <div className="container-info-planta">
                    <p className="titulo">
                        RUTINA DE ENSAYOS DE AEROGENERADORES EN PARQUES EÓLICOS
                    </p>
                    <p className="cuerpo">
                        Realizamos estudios integrales de sistemas de aislamiento de generadores
                        sincrónicos y asincrónicos en potencias de hasta 5MW. Se elaboran
                        informes técnicos detallados correspondientes a cada máquina particular,
                        más informes generales con listas de recomendaciones y asignación de
                        prioridades para intervención. Esto permite optimizar la programación
                        del sector de mantenimiento basándose en un orden de prioridades según
                        el estado de cada máquina, independientemente de las horas de
                        utilización.
                    </p>
                    <div className="planta-container-btn">
                        <button onClick={descargarPDF}>
                            DESCARGAR PDF
                        </button>
                        <button onClick={props.openModalMantenimiento}>
                            REALICE SU CONSULTA
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="galeria-img">
                    <div className="img-principal"
                         onClick={(e) => props.onClick(e, {index: 0, lightboxNum: 1})}>
                        <img src={foto1} alt=""/>
                    </div>

                    <div className="img-thumb">
                        <div className="thumb"
                             onClick={(e) => props.onClick(e, {index: 1, lightboxNum: 1})}
                        >
                            <img src={foto2} alt=""/>
                        </div>
                        <div className="thumb"
                             onClick={(e) => props.onClick(e, {index: 2, lightboxNum: 1})}
                        >
                            <img src={foto3} alt=""/>
                        </div>
                        <div className="thumb"
                             onClick={(e) => props.onClick(e, {index: 3, lightboxNum: 1})}
                        >
                            <img src={foto4} alt=""/>
                        </div>
                        <LightBox images={[
                            {src: foto1},
                            {src: foto2},
                            {src: foto3},
                            {src: foto4}
                        ]}
                                  onClose={props.onClose}
                                  onClickPrev={props.onClickPrev}
                                  onClickNext={props.onClickNext}
                                  currentImage={props.currentImage}
                                  isOpen={props.open}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>;
}