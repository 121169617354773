import React from 'react';
import "../../../assets/css/FormCotizacion.css";
import $ from "jquery";
//Components


//Img


class FormCotizacion extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                nombre: "",
                email: "",
                telefono: "",
                producto: "",
                urlProducto: "",
            },
            error: null,
            spinner: null

        };

        this.submitForm = this.submitForm.bind(this);
        this.changeForm = this.changeForm.bind(this);
    }

    componentDidMount() {
        this.setState({
            form: {
                producto: this.props.producto.nombre,
                urlProducto: `https://fabrifor.com/producto/${this.props.producto.id}`
            }
        });
    }

    submitForm(e) {
        e.preventDefault();
        $.ajax({
            url: process.env.PUBLIC_URL + "/php/cotizacionForm.php",
            type: 'POST',
            data: this.state.form,
            // dataType: 'json',
            dataType: 'html',
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            beforeSend: function (data) {
                this.setState({spinner: true});
            }.bind(this),
            cache: false,
            success: function () {
                // console.log("success");
                this.setState({error: false});
                this.setState({spinner: false});
            }.bind(this),
            // Fail..
            error: function (xhr, status, err) {
                // console.log(err);
                this.setState({error: true});
                this.setState({spinner: false});
            }.bind(this)
        });
    }

    changeForm(e) {
        var cambio = this.state.form;
        cambio[e.target.id] = e.target.value;
        this.setState({form: cambio});
    }

    render() {
        return (
            <div id={"cotizacion"} hidden={!this.props.hidden}>
                <section className="cotizacion">
                    <div className="modal">
                        <div className="cruz-cerrar" alt="cerrar" onClick={this.props.closeModal}>&times;</div>
                        <div className="modal-body">
                            <h4>Para solicitar la cotización del producto
                                por favor ingresá los siguientes datos</h4>
                            <form onSubmit={(e) => this.submitForm(e)}>
                                <input id="nombre" required={true} onChange={(e) => this.changeForm(e)}
                                       type="text" placeholder="Nombre y Apellido"/>
                                <input id="email" required={true} onChange={(e) => this.changeForm(e)}
                                       type="email" placeholder="E-Mail"/>
                                <input id="telefono" required={true} onChange={(e) => this.changeForm(e)}
                                       type="text" placeholder="Telefono"/>
                                <div className="btn-submit-container">
                                    {this.state.spinner ?
                                        <div className="lds-dual-ring white"></div>
                                        :
                                        <button type="submit">ENVIAR DATOS</button>
                                    }
                                </div>
                                <p className="mensaje"
                                   style={{display: this.state.error == false ? "block" : "none"}}>¡Mensaje enviado con
                                    éxito!</p>
                            </form>
                        </div>
                    </div>
                </section>
            </div>

        );
    }
}

export default FormCotizacion;
