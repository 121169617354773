import React from 'react';
import "../../../assets/css/Galeria.css";
import Gallery from 'react-photo-gallery';
import LightBox from 'react-images';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/src/owl.carousel.css';
//Components


//Img
import foto1_thumb from "../../../assets/images/galeria/foto1-thumb.png";
import foto2_thumb from "../../../assets/images/galeria/foto2-thumb.png";
import foto3_thumb from "../../../assets/images/galeria/foto3-thumb.png";
import foto4_thumb from "../../../assets/images/galeria/foto4-thumb.png";

import foto5_thumb from "../../../assets/images/galeria/foto5-thumb.png";
import foto6_thumb from "../../../assets/images/galeria/foto6-thumb.png";
import foto7_thumb from "../../../assets/images/galeria/foto7-thumb.png";
import foto8_thumb from "../../../assets/images/galeria/foto8-thumb.png";

import foto9_thumb from "../../../assets/images/galeria/foto9-thumb.png";
import foto11_thumb from "../../../assets/images/galeria/foto11-thumb.png";
import foto12_thumb from "../../../assets/images/galeria/foto12-thumb.png";
import foto13_thumb from "../../../assets/images/galeria/foto13-thumb.png";
import foto14_thumb from "../../../assets/images/galeria/foto14-thumb.png";


import foto1 from "../../../assets/images/galeria/foto1.png";
import foto2 from "../../../assets/images/galeria/foto2.png";
import foto3 from "../../../assets/images/galeria/foto3.png";
import foto4 from "../../../assets/images/galeria/foto4.png";
import foto5 from "../../../assets/images/galeria/foto5.png";
import foto6 from "../../../assets/images/galeria/foto6.png";
import foto7 from "../../../assets/images/galeria/foto7.png";
import foto8 from "../../../assets/images/galeria/foto8.png";
import foto9 from "../../../assets/images/galeria/foto9.png";
import foto11 from "../../../assets/images/galeria/foto11.png";
import foto12 from "../../../assets/images/galeria/foto12.png";
import foto13 from "../../../assets/images/galeria/foto13.png";
import foto14 from "../../../assets/images/galeria/foto14.png";

class Galeria extends React.Component {

    constructor(props) {
        super(props);
        this.state = {currentImage: 0, modalIsOpen: false};
        this.closeLightbox = this.closeLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);

        this.photos = [
            {src: foto1_thumb, width: 1, height: 1},
            {src: foto2_thumb, width: 1, height: 1},
            {src: foto3_thumb, width: 1, height: 1},
            {src: foto4_thumb, width: 1, height: 1},
            {src: foto5_thumb, width: 1, height: 1},
            {src: foto6_thumb, width: 1, height: 1},
            {src: foto7_thumb, width: 1, height: 1},
            {src: foto8_thumb, width: 1, height: 1},
            {src: foto9_thumb, width: 1, height: 1},
            {src: foto11_thumb, width: 1, height: 1},
            {src: foto12_thumb, width: 1, height: 1},
            {src: foto13_thumb, width: 1, height: 1},
            {src: foto14_thumb, width: 1, height: 1},

        ];

        this.lightbox = [
            {src: foto1, width: 4, height: 4},
            {src: foto2, width: 4, height: 4},
            {src: foto3, width: 4, height: 4},
            {src: foto4, width: 4, height: 4},
            {src: foto5, width: 4, height: 4},
            {src: foto6, width: 4, height: 4},
            {src: foto7, width: 4, height: 4},
            {src: foto8, width: 4, height: 4},
            {src: foto9, width: 4, height: 4},
            {src: foto11, width: 4, height: 4},
            {src: foto12, width: 4, height: 4},
            {src: foto13, width: 4, height: 4},
            {src: foto14, width: 4, height: 4},
        ];
    }

    openLightbox(event, obj) {
        this.setState({
            currentImage: obj.index,
            lightboxIsOpen: true,
        });
    }

    closeLightbox() {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }

    gotoPrevious() {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    }

    gotoNext() {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    }

    render() {
        let lsb = [];
        let msb = [];
        // console.log(msb);
        // console.log(lsb);
        this.photos.some(
            (photo, i) => {
                if (i < 2) {
                    lsb.push(photo);
                } else {
                    msb.push(photo);
                }

            }
        );

        var imagenes = [];
        imagenes = this.photos;
        //condicion para galeria de imagenes con mas de 4, no aparecen en la preview pero si cuando haces click
        if (this.lightbox && this.lightbox.length > 0)
            imagenes = this.photos.concat(this.lightbox);

        return (
            <div>
                <section className="galeria">
                    <div className="galeria-xl" style={{
                        textAlign: "center",
                        paddingLeft: "75px",
                        paddingRight: "75px",
                        backgroundColor: "#091a2e"
                    }}>
                        <div className="galeria-md">
                            <Gallery columns={4} margin={0} photos={this.photos} onClick={this.openLightbox}/>
                        </div>
                        <LightBox images={this.lightbox}
                                  onClose={this.closeLightbox}
                                  onClickPrev={this.gotoPrevious}
                                  onClickNext={this.gotoNext}
                                  currentImage={this.state.currentImage}
                                  isOpen={this.state.lightboxIsOpen}
                        />
                    </div>
                    <div className="galeria-responsive">
                        <Gallery columns={2} margin={0} photos={this.photos} onClick={this.openLightbox}/>
                    </div>
                </section>
            </div>
        );
    }
}

export default Galeria;
