import React from 'react';
import "../../../assets/css/Contacto.css";
import $ from "jquery";

//Components


//Img
import facebook from "../../../assets/images/redes/facebook.png";
import whatsapp from "../../../assets/images/redes/whatsapp.png";
import linkedin from "../../../assets/images/redes/linkedin.png";

class Contacto extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                nombre: "",
                email: "",
                mensaje: ""
            },
            error: null,
            spinner: null

        };

        this.submitForm = this.submitForm.bind(this);
        this.changeForm = this.changeForm.bind(this);
    }

    submitForm(e) {
        e.preventDefault();
        $.ajax({
            url: process.env.PUBLIC_URL + "/php/contactForm.php",
            type: 'POST',
            data: this.state.form,
            // dataType: 'json',
            dataType: 'html',
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            beforeSend: function (data) {
                this.setState({spinner: true});
            }.bind(this),
            cache: false,
            success: function () {
                // console.log("success");
                this.setState({error: false});
                this.setState({spinner: false});
            }.bind(this),
            // Fail..
            error: function (xhr, status, err) {
                // console.log(err);
                this.setState({error: true});
                this.setState({spinner: false});
            }.bind(this)
        });
    }

    changeForm(e) {
        var cambio = this.state.form;
        cambio[e.target.id] = e.target.value;
        this.setState({form: cambio});
    }

    render() {
        return (
            <div id={"contacto"}>
                <section className="contacto">
                    <div className="container-limit">
                        <p className="titulo">Podés realizar tu consulta a través del formulario, de nuestras vías de
                            contacto ó comunicándote
                            telefónicamente en nuestros horarios de atención.</p>

                        <div className="container-doble">
                            <div className="col-6">
                                <div style={{paddingLeft: "15px", paddingRight: "15px"}}>
                                    <h6>Dejanos tu consulta</h6>
                                    <form onSubmit={(e) => this.submitForm(e)}>
                                        <div className="form-fields">
                                            <input id="nombre" required={true} onChange={(e) => this.changeForm(e)}
                                                   type="text" placeholder="Nombre y Apellido"/>
                                            <input id="email" required={true} onChange={(e) => this.changeForm(e)}
                                                   type="email" placeholder="E-Mail"/>
                                            <textarea id="mensaje" placeholder="Mensaje..." name="" cols="30"
                                                      rows="10" onChange={(e) => this.changeForm(e)}/>
                                        </div>
                                        {this.state.spinner ?
                                            <div className="lds-dual-ring" style={{marginLeft:"25px"}}></div>
                                            :
                                            <button type="submit">ENVIAR</button>
                                        }
                                        <p className="mensaje" style={{display:this.state.error == false ? "block" : "none"}}>¡Mensaje enviado con éxito!</p>
                                    </form>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="infoAdicional-container"
                                     style={{paddingLeft: "15px", paddingRight: "15px"}}>
                                    <h6 className="infoAdicional">Información adicional</h6>
                                    <h6>Nuestros Horarios</h6>
                                    <p>Lun - Vie: 08:00 - 12:00, 13:30 a 18:00</p>
                                    <p className="pline">Sab, Dom: Cerrado</p>
                                    <h6>Seguinos</h6>
                                    <div className="container-redes">
                                        <a alt="Facebook" target="_blank" href="https://www.facebook.com/fabrifor"><img src={facebook} alt=""/></a>
                                        <a alt="Hablá con nosotros" href="https://api.whatsapp.com/send?phone=5493416537014" target="_blank"><img src={whatsapp} alt=""/></a>
                                        {/*<a href=""><img src={linkedin} alt=""/></a>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </div>

        );
    }
}

export default Contacto;
