import React from 'react';
import '../../../assets/css/AnalisisEstatico.css';

export default function AnalisisEstatico() {
    return (
        <div id="analisisEstaticos">
            <section className="analisisEstaticos analisis">
                <div className="container-limit">
                    <div className="container-titulo">
                        <h3><b>ANÁLISIS ESTÁTICO</b>&nbsp;DE MÁQUINAS ELÉCTRICAS</h3>
                    </div>
                    <div className="container-doble">
                        <div className="col-6">
                            <div className="container-text">
                                <p>
                                    El monitoreo de estado de motores y generadores mediante ensayos estáticos está
                                    orientado a brindar al cliente la posibilidad de contar con información que le
                                    permita conocer la evolución de las máquinas críticas de la planta, no sólo
                                    eliminando la raíz causa de problemas que pasan inadvertidos y generan defectos sino
                                    también permitiendo la posibilidad de predecir fallas a fin de tomar medidas
                                    correctivas y evitar tiempos muertos indeseados.
                                </p>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="container-text">
                                <p>
                                    Fabrifor ofrece a sus clientes un nuevo servicio orientado a optimizar el
                                    tratamiento de motores por medio de ensayos estáticos en planta, persiguiendo el
                                    único objetivo crear un funcionamiento orgánico entre la industria y su asistencia
                                    técnica y optimizando la producción por medio de mecanismos de predicción de fallas.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ensayosDisponibles">
                <div className="container-limit">
                    <div className="container-titulo">
                        <h4>ENSAYOS DISPONIBLES</h4>
                    </div>
                    <div className="container-doble">
                        <div className="col-6">
                            <ul>
                                <li>
                                    Balanceo resistivo de bobinado
                                </li>
                                <li>
                                    Inductancia, impedancia y capacidad geométrica
                                </li>
                                <li>
                                    Factor de Disipación y Calidad D/Q
                                </li>
                                <li>
                                    Resistencia de aislamiento
                                </li>
                            </ul>
                        </div>
                        <div className="col-6">
                            <ul>
                                <li>
                                    Indice de Polarización/Absorción Dieléctrica
                                </li>
                                <li>
                                    HiPot C.C. en escalones
                                </li>
                                <li>
                                    Prueba de Impulso-Surge Test
                                </li>
                                <li>
                                    Descargas Parciales
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}