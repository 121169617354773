import React from 'react';
import "../../../assets/css/Producto.css";
import {Link} from "react-router-dom";
//Components


//Img
import logoWeg from "../../../assets/images/wegLogo.png";

//imgProducto
import ProductosRelacionados from "./ProductosRelacionados";
import FormCotizacion from "./FormCotizacion";
import data from "../../../data/productos";

class Producto extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showCotizacion: false
        }

        this.openModalCotizacion = this.openModalCotizacion.bind(this);
        this.closeModalCotizacion = this.closeModalCotizacion.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.idProducto != this.props.match.params.idProducto) {
            window.scrollTo({top: 0, left: 0, behavior: "smooth"});
        }
    }

    openModalCotizacion() {
        this.setState({showCotizacion: true});
    }

    closeModalCotizacion() {
        this.setState({showCotizacion: false});
    }

    render() {
        let idProducto = this.props.match.params.idProducto;
        let productoItem = [];
        data.categorias.map((categoria) => {
            categoria.subCategorias.map((subCategoria) => {
                subCategoria.productos.some((producto) => {
                    productoItem.push(producto)
                });
            });
        });

        let item = productoItem[idProducto - 1];

        let itemRel = item.idProductosRel;
        let productoItemRel = []
        itemRel.some(key => {
            productoItemRel.push(
                productoItem[key - 1]
            )
        });

        let nombreCorto = item.nombreCorto.map(key => {
            return (
                key + " "
            )
        });

        let imgRes = [];
        let imgThumb = []


        item.img.some((key, i) => {
            imgRes.push(
                <div className="img-big">
                    <img src={`${process.env.PUBLIC_URL}/productos/img/${item.folder}/${key}.png`} alt=""/>
                </div>
            );

            if (i != 0)
                imgThumb.push(
                    <div className="thumb">
                        <img src={`${process.env.PUBLIC_URL}/productos/img/${item.folder}/${key}.png`} alt=""/>
                    </div>
                )
        });

        return (
            <div id={"producto"}>
                <FormCotizacion producto={item} hidden={this.state.showCotizacion} closeModal={this.closeModalCotizacion}/>
                <section className="producto">
                    <div className="container-limit">
                        <div className="breadcrumb">
                            <ul>
                                <li><Link to="/productos">PRODUCTOS</Link></li>
                                <li><Link
                                    to={`/productos/${item.idCateg}`}>{data.categorias[item.idCateg - 1].nombre}</Link>
                                </li>
                                <li>{item.nombre}</li>
                            </ul>
                        </div>
                        <div className="container-doble">
                            <div className="col-6">

                                <div className="galeria-img res">
                                    <div className="img-principal">
                                        {imgRes}
                                    </div>
                                </div>

                                <h2>{item.nombreDetalle}</h2>
                                <h3>{nombreCorto}</h3>
                                <div className="container-logo-boton res">
                                    <img src={logoWeg} alt=""/>
                                    <button onClick={() => this.openModalCotizacion()}>PEDIR COTIZACIÓN
                                    </button>
                                </div>
                                <div className="info">
                                    <h6>ESPECIFICACIONES:</h6>
                                    <p dangerouslySetInnerHTML={{__html: item.especificaciones}}></p>
                                </div>
                                <div className="container-logo-boton">
                                    <img src={logoWeg} alt=""/>
                                    <button onClick={() => this.openModalCotizacion()}>PEDIR COTIZACIÓN</button>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="galeria-img">
                                    <div className="img-principal">
                                        <img
                                            src={`${process.env.PUBLIC_URL}/productos/img/${item.folder}/${item.img[0]}.png`}
                                            alt=""/>
                                    </div>
                                    {
                                        imgThumb.length > 0 ?
                                            <div className="img-thumb">
                                                {imgThumb}
                                            </div> :
                                            ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ProductosRelacionados productosRel={productoItemRel}/>
            </div>
        );
    }
}

export default Producto;
