export default {
    "web": "Fabrifor",
    "version": "1.0",
    "categorias": [
        {
            "id": 1,
            "nombre": "MOTORES ELÉCTRICOS",
            "subCategorias": [
                {
                    "id": 1,
                    "nombre": "MOTORES TRIFÁSICOS DE INDUCCIÓN",
                    "productos": [
                        {
                            "id": 1,
                            "nombre": "MOTORES ELÉCTRICOS TRIFÁSICOS LÍNEA W22",
                            "nombreDetalle": "MOTORES TRIFÁSICOS DE INDUCCIÓN",
                            "nombreCorto": [
                                "LÍNEA W22"
                            ],
                            "especificaciones": "<b>Potencia:</b> 0,25 hasta 550 CV (0,18 hasta 400 kW)<br/><b>Polos:</b> II, IV, VI, VIII - Forma Constructiva: IMB3 <br/><b>Carcasa en hierro fundido:</b> 63 hasta 355 (Carcasas Nemabajo consulta) <br/><b>Tensión:</b> 220/380Vca - 380/660Vca <br/><b>Frecuencia:</b> 50 ó 60 Hz <br/><b>Aislación:</b> clase “F” - Δt 80ºC <br/><b>Protección:</b> IPW55 (Carcasa 180 y Superior) <br/><b>Termistores:</b> (01 x fase) en carcasas 160 a 355 de línea <br>Conforme a las normas ABNT, IEC y NEMA <br/>Alemite reengrase en carcasas 225 y superior. <br/>Motores \"Top Premium Efficiency - IE3\": Disponible bajo <br/>consulta.",
                            "folder": "w22",
                            "img": ["foto1", "foto2", "foto3", "foto4"],
                            "idProductosRel": [
                                2,
                                3,
                                4
                            ],
                            "idCateg": 1,
                            "idSubCategoria": 1
                        }
                    ]
                },
                {
                    "id": 2,
                    "nombre": "MOTORES A PRUEBA DE EXPLOSIÓN",
                    "productos": [
                        {
                            "id": 2,
                            "nombre": "MOTORES ELÉCTRICOS A PRUEBA DE EXPLOSIÓN",
                            "nombreDetalle": "MOTORES ELÉCTRICOS A PRUEBA DE EXPLOSIÓN",
                            "nombreCorto": [
                                "LÍNEA EEXD/EEXDE IIB T4"
                            ],
                            "especificaciones": "<b>Motores certificados por CESI según directivas ATEX</b><br><b>Potencia:</b> 0,5 hasta 450cv (0.37 hasta 330kW)<br><b>Polos:</b> II, IV, VI, VIII - Forma Constructiva: IMB3 <br><b>Carcasa en hierro fundido:</b> 90 S hasta 355 M/L (Carcasas Nema bajo consulta) <br><b>Tensión:</b> 220/380Vca - 380/660Vca <br><b>Frecuencia:</b> 50 ó 60 Hz <br><b>Aislación:</b> clase “F” - Δt 80ºC <br><b>Protección:</b> IP55 <br><b>Termistores:</b> (01 x fase) en carcasas 90 a 355 de línea (Todos los motores) <br>Conforme a las normas ABNT, IEC y NEMA <br>Carcasa, caja de conexión y tapas con ensayo hidrostático (100%) <br>",
                            "folder": "eexd",
                            "img": ["foto1"],
                            "idProductosRel": [
                                5,
                                3,
                                4
                            ],
                            "idCateg": 1,
                            "idSubCategoria": 2
                        }
                    ]
                },
                {
                    "id": 3,
                    "nombre": "MOTORES MONOFÁSICOS",
                    "productos": [
                        {
                            "id": 3,
                            "nombre": "MOTORES MONOFÁSICOS LÍNEA: MON-IP21 / MTE-IP55 / MME-IP55",
                            "nombreDetalle": "MOTORES MONOFÁSICOS",
                            "nombreCorto": [
                                "LÍNEA MON - IP21",
                                "LÍNEA MTE - IP55",
                                "LÍNEA MME - IP55"
                            ],
                            "especificaciones": "<b>Potencia:</b> 0,25 hasta 5cv<br><b>Polos:</b> II, IV - Forma Constructiva: IMB3<br><b>Tensión:</b> 220Vca<br><b>Frecuencia:</b> 50 Hz<br><b>Aislación:</b> clase “F” - Δt 80º<br><b>Protección:</b> IP21 o IP55<br>",
                            "folder": "mono",
                            "img": ["foto1", "foto2","foto3"],
                            "idProductosRel": [
                                5,
                                1,
                                4
                            ],
                            "idCateg": 1,
                            "idSubCategoria": 3
                        }
                    ]
                },
                {
                    "id": 4,
                    "nombre": "SERVOMOTORES",
                    "productos": [
                        {
                            "id": 4,
                            "nombre": "SERVOMOTORES SWA SERVOCONVERTIDORES SCA 06",
                            "nombreDetalle": "SERVOMOTORES",
                            "nombreCorto": [
                                "SERVOMOTORES SWA",
                                "SERVOCONVERTIDORES SCA 06"
                            ],
                            "especificaciones": "<b>Alimentación:</b> Monofásica: 220 Vca y Trifásica de 380VCA - 50/60Hz.<br><b>Torque:</b> hasta 50Nm<br><b>Revoluciones:</b> hasta 6000 rpm<br>Realimentación por resolver<br>Servomotores con medidas según norma IEC<br><b>Modos de trabajo:</b><br>- Velocidad<br>- Posicionamiento<br>- Torque<br><b>Funciones incorporadas:</b><br>- Cero máquina (homming)<br>- Electronic gear box<br><b>Comunicación en red:</b> Profibus, Devicenet, Modbus.<br>",
                            "folder": "servo",
                            "img": ["foto1"],
                            "idProductosRel": [
                                2,
                                1,
                                5
                            ],
                            "idCateg": 1,
                            "idSubCategoria": 4
                        }
                    ]
                },
                {
                    "id": 5,
                    "nombre": "ALTERNADORES SIN ESCOBILLAS",
                    "productos": [
                        {
                            "id": 5,
                            "nombre": "ALTERNADORES SIN ESCOBILLAS GTA",
                            "nombreDetalle": "ALTERNADORES SIN ESCOBILLAS",
                            "nombreCorto": [
                                "LÍNEA GTA"
                            ],
                            "especificaciones": "<b>Factor de potencia:</b> 0,8 a 1,0<br><b>Velocidad:</b> 1500 rpm (50Hz)<br><b>Aislación Clase:</b> H<br><b>Normas:</b> ABNT/VDE/IEC<br><b>Servicio Continuo:</b> S1<br><b>Grado de protección:</b> IP21<br><b>Forma Constructiva:</b><br>- B15T Simple rodamiento<br>- B3T Doble rodamiento<br>",
                            "folder": "gta",
                            "img": ["foto1"],
                            "idProductosRel": [
                                2,
                                1,
                                4
                            ],
                            "idCateg": 1,
                            "idSubCategoria": 5
                        }
                    ]
                }
            ]
        },
        {
            "id": 2,
            "nombre": "ARRANCADORES SUAVES Y CONVERTIDORES DE FRECUENCIA",
            "subCategorias": [
                {
                    "id": 6,
                    "nombre": "ARRANCADORES SUAVES",
                    "productos": [
                        {
                            "id": 6,
                            "nombre": "ARRANCADORES SUAVES SSW 05",
                            "nombreDetalle": "ARRANCADORES SUAVES",
                            "nombreCorto": [
                                "SSW05"
                            ],
                            "especificaciones": "<b>Alimentación Trifásica: </b> 220 a 460Vca - 50/60Hz<br><b>Potencia:</b> hasta 60cv (380Vca)<br><b>Funciones incorporadas:</b><br>- Protección electrónica del motor<br>- Arranque por rampa de tensión<br>- Amplia tensión de comando: 90 a 250 VCA<br>- Control de potencia en dos fases<br>- By-pass incorporado<br>- Comunicación en red: Modbus.<br>",
                            "folder": "ssw05",
                            "img": ["foto1"],
                            "idProductosRel": [
                                7,
                                8,
                                12
                            ],
                            "idCateg": 2,
                            "idSubCategoria": 6
                        },
                        {
                            "id": 7,
                            "nombre": "ARRANCADORES SUAVES SSW 06",
                            "nombreDetalle": "ARRANCADORES SUAVES",
                            "nombreCorto": [
                                "SSW 06"
                            ],
                            "especificaciones": "<b>Alimentación Trifásica:</b> 220 a 575Vca - 50/60Hz (690Vca Bajo consulta)<br><b>Potencia:</b> hasta 1000cv (Conexión a tres cables - 380Vca)<br><b>Funciones incorporadas:</b><br>- Protección electrónica del motor y arrancador.<br>- Arranque por rampa de tensión<br>- Arranque por limitación de corriente<br>- Arranque con control de torque<br>- By-pass incorporado<br>- Comunicación en red: Profibus, Devicenet, Modbus,<br>Ethernet TCP/IP<br>",
                            "folder": "ssw06",
                            "img": ["foto1"],
                            "idProductosRel": [
                                6,
                                8,
                                12
                            ],
                            "idCateg": 2,
                            "idSubCategoria": 7
                        },
                        {
                            "id": 8,
                            "nombre": "ARRANCADORES SUAVES SSW 07",
                            "nombreDetalle": "ARRANCADORES SUAVES",
                            "nombreCorto": [
                                "SSW 07"
                            ],
                            "especificaciones": "<b>Alimentación Trifásica:</b> 220 a 575Vca - 50/60Hz<br><b>Potencia:</b> hasta 300cv (380Vca)<br><b>Funciones incorporadas:</b><br>- Protección electrónica del motor y arrancador.<br>- Arranque por rampa de tensión<br>- Arranque por limitación de corriente<br>- Control de potencia en las tres fases<br>- By-pass incorporado<br>- Comunicación en red: Modbus.<br>",
                            "folder": "ssw07",
                            "img": ["foto1"],
                            "idProductosRel": [
                                6,
                                7,
                                12
                            ],
                            "idCateg": 2,
                            "idSubCategoria": 8
                        }
                    ]
                },
                {
                    "id": 7,
                    "nombre": "CONVERTIDORES DE FRECUENCIA",
                    "productos": [
                        {
                            "id": 9,
                            "nombre": "CONVERTIDORES DE FRECUENCIA CFW 100",
                            "nombreDetalle": "CONVERTIDORES DE FRECUENCIA",
                            "nombreCorto": [
                                "CFW 100"
                            ],
                            "especificaciones": "<b>Alimentación Monofásica:</b> hasta 240Vca -50/60Hz.<br><b>Potencia:</b> hasta 1cv<br><b>Funciones incorporadas:</b><br>- Control Vectorial VVW<br>- Curva V/F lineal y cuadrática.<br>- Soft PLC incorporado<br>- HMI remoto<br>- Frenado con inyección de corriente continua.<br>",
                            "folder": "cfw100",
                            "img": ["foto1"],
                            "idProductosRel": [
                                10,
                                11,
                                12
                            ],
                            "idCateg": 2,
                            "idSubCategoria": 9
                        },
                        {
                            "id": 10,
                            "nombre": "CONVERTIDORES DE FRECUENCIA CFW 300",
                            "nombreDetalle": "CONVERTIDORES DE FRECUENCIA",
                            "nombreCorto": [
                                "CFW 300"
                            ],
                            "especificaciones": "<b>Alimentación Monofásica:</b> hasta 240Vca -50/60Hz.<br><b>Potencia:</b> hasta 3cv.<br><b>Funciones incorporadas:</b><br>- Control Vectorial VVW<br>- Curva V/F lineal y cuadrática.<br>- Soft PLC incorporado<br>- HMI remoto<br>- Frenado con inyección de corriente continua.<br>",
                            "folder": "cfw300",
                            "img": ["foto1"],
                            "idProductosRel": [
                                12,
                                11,
                                9
                            ],
                            "idCateg": 2,
                            "idSubCategoria": 10
                        },
                        {
                            "id": 11,
                            "nombre": "CONVERTIDORES DE FRECUENCIA CFW 500",
                            "nombreDetalle": "CONVERTIDORES DE FRECUENCIA",
                            "nombreCorto": [
                                "CFW 500"
                            ],
                            "especificaciones": "<b>Potencia:</b> hasta 3cv (220Vca) y hasta 30cv (380Vca)<br>Módulo Plug-in incorporado<br><b>Funciones incorporadas:</b><br>- Control Vectorial VVW<br>- Curva V/F lineal y cuadrática.<br>- Soft PLC incorporado<br>- HMI remoto<br>- Puerto de comunicación RS485<br>- Fuente de Alimentación 24V<br>",
                            "folder": "cfw500",
                            "img": ["foto1"],
                            "idProductosRel": [
                                12,
                                10,
                                9
                            ],
                            "idCateg": 2,
                            "idSubCategoria": 11
                        },
                        {
                            "id": 12,
                            "nombre": "CONVERTIDORES DE FRECUENCIA CFW 11",
                            "nombreDetalle": "CONVERTIDORES DE FRECUENCIA",
                            "nombreCorto": [
                                "CFW 11"
                            ],
                            "especificaciones": "<b>Alimentación Monofásica:</b> hasta 220Vca -50/60Hz.<br><b>Alimentación Trifásica:</b> de 220 a 690Vca - 50/60Hz.<br><b>Potencia (HD):</b> hasta 3cv (Monofásica) y 700cv (Trifásica).<br><b>Funciones incorporadas:</b><br>- Multispeed de 8 velocidades.<br>- Control vectorial sensorless.<br>- Control de lazo cerrado PID.<br>- Frenado con inyección de corriente continua.<br>- Control de Torque.<br>- Soft PLC (Control de Bombas, Bobinadoras y<br>Debobinadoras).<br>- Display gráfico retroiluminado.<br>- Reloj en tiempo real.<br>- Comunicación en red: Profibus, Devicenet, Modbus,<br>Ethernet TCP/IP<br>",
                            "folder": "cfw011",
                            "img": ["foto1", "foto2", "foto3", "foto4"],
                            "idProductosRel": [
                                10,
                                11,
                                9
                            ],
                            "idCateg": 2,
                            "idSubCategoria": 12
                        }
                    ]
                }
            ]
        }
    ]
}