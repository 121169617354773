import React from 'react';
import "../../../assets/css/BotonesCategorias.css";
//Components


//Img
import converFreq from "../../../assets/images/catalogo/convFreq.png";
import arracSuave from "../../../assets/images/catalogo/arrSuav.png";
import motores from "../../../assets/images/catalogo/motoresElectricos.png";

class BotonesCategorias extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    componentWillUnmount() {

    }

    render() {
        let botones = null;
        switch (this.props.opc) {
            case 1:
                botones =
                    <div className="container-tarjetas-categ">
                        <div className="tarjeta-categ" onClick={() => this.props.push(7)}>
                            <h2>CONVERTIDORES <b>DE FRECUECIA</b></h2>
                            <img src={converFreq} alt=""/>
                        </div>
                        <div className="tarjeta-categ right" onClick={() => this.props.push(6)}>
                            <h2>ARRANCADORES <b>SUAVES</b></h2>
                            <img src={arracSuave} alt=""/>
                        </div>
                    </div>;
                break;
            case 2:
                botones =
                    <div className="container-tarjetas-categ">
                        <div className="tarjeta-categ" onClick={() => this.props.push(1)}>
                            <h2>MOTORES <b>ELÉCTRICOS</b></h2>
                            <img src={motores} alt=""/>
                        </div>
                        <div className="tarjeta-categ right" onClick={() => this.props.push(6)}>
                            <h2>ARRANCADORES <b>SUAVES</b></h2>
                            <img src={arracSuave} alt=""/>
                        </div>
                    </div>;
                break;
            case 3:
                botones =
                    <div className="container-tarjetas-categ">
                        <div className="tarjeta-categ" onClick={() => this.props.push(1)}>
                            <h2>MOTORES <b>ELÉCTRICOS</b></h2>
                            <img src={motores} alt=""/>
                        </div>
                        <div className="tarjeta-categ" onClick={() => this.props.push(7)}>
                            <h2>CONVERTIDORES <b>DE FRECUECIA</b></h2>
                            <img src={converFreq} alt=""/>
                        </div>
                    </div>;
                break;

        }
        return (
            <div id={"botones-categ"}>
                <section className="botones-categ">
                    {botones}
                </section>
            </div>

        );
    }
}

export default BotonesCategorias;
