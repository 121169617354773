import React from 'react';
import {withRouter, Route, Switch} from 'react-router-dom';

//Components
import Home from "./Layout/Home";
import Nav from "./Layout/ElementosLayout/Nav";
import Catalogo from "./Layout/Catalogo";
import Footer from "./Layout/Home/Footer";
import NavSocial from "./Layout/NavSocial/NavSocial";
import Producto from "./Layout/Catalogo/Producto";
import MantenimientoPredictivo from "./Layout/MantenimientoPredictivo/MantenimientoPredictivo";




class Layout extends React.Component {
    render() {
        return (
            <div>
                <Nav/>
                <Switch>
                    <Route path="/" exact component={Home}/>
                    <Route path="/mantenimientoPredictivo" component={MantenimientoPredictivo}/>
                    <Route path="/productos/:idCategoria?" component={Catalogo}/>
                    <Route path="/producto/:idProducto" component={Producto}/>
                </Switch>
                <Footer/>
                <NavSocial/>
            </div>
        );
    }
}

export default withRouter(Layout);
