import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';

//Router
import {Switch} from 'react-router';
import {Route, Router} from "react-router-dom";
import history from './history';

//CSS
import './assets/css/index.css';

//Components
import Layout from "./components/Layout";

// import * as serviceWorker from './serviceWorker';


if (process.env.NODE_ENV !== 'production') {
    console.log("Estoy en debug :)");
}
else {
    console.log("Estoy en produccion 8-)");
    //chau console.los metodo 4 de esta pagina https://stapp.space/how-to-remove-javascript-console-log-pros-2/
    (function () {
        var method;
        var noop = function noop() {
        };
        var methods = [
            'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
            'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
            'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
            'timeStamp', 'trace', 'warn'
        ];
        var length = methods.length;
        var console = (window.console = window.console || {});

        while (length--) {
            method = methods[length];
            console[method] = noop;
        }
    }());
}

ReactDOM.render(
        <Router history={history}>
            <Switch>
                <Route path="/" component={Layout}/>
            </Switch>
        </Router>,
    document.getElementById('root')
);

// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
