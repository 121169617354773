import React from 'react';
import "../../../assets/css/ProductosRelacionados.css";
import {Link} from "react-router-dom";
//Components


//Img


class ProductosRelacionados extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    componentWillUnmount() {

    }

    render() {
        let productos = this.props.productosRel;

        let items = productos.map(key => {
            return (
                <Link to={`/producto/${key.id}`} style={{textDecoration:"none"}}>
                    <div className="tarjeta-producto">
                        <h6>{key.nombre}</h6>
                        <img
                            src={`${process.env.PUBLIC_URL}/productos/img/${key.folder}/${key.img[0]}.png`}
                            alt=""/>
                    </div>
                </Link>
            )
        });
        return (
            <div id={"productos-relacionados"}>
                <section className="productos-relacionados">
                    <div className="container-limit">
                        <h2>PRODUCTOS RELACIONADOS</h2>
                        <div className="grilla-productos">
                            {items}
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default ProductosRelacionados;
