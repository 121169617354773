import React from 'react';
import "../../../assets/css/Servicios.css";

//Components


//Img
import icon1 from "../../../assets/images/icon-rebobinados.png";
import icon2 from "../../../assets/images/icon-mantenimiento.png";
import icon3 from "../../../assets/images/icon-venta.png";

class Servicios extends React.Component {

    render() {
        return (
            <div id="servicios">
                <section className="servicios">
                    <div className="container-limit">
                        <div className="container-titulo">
                            <h3>NUESTROS&nbsp;<b>SERVICIOS</b>&nbsp;</h3>
                        </div>
                        <div className="container-parrafo">
                            <p>Disponemos de todos nuestros recursos para lograr los objetivos pactados desde el
                                momento en que un trabajo ingresa a nuestras instalaciones, con el fin de que nuestros
                                clientes puedan disponer de un excelente servicio con la celeridad que amerite
                                según el caso.</p>
                        </div>
                        <div className="container-tarjetas">
                            <div className="tarjeta-servicio">
                                <div className="tarjeta-titulo">
                                    <div className="tarjeta-titulo-flex">
                                        <img src={icon1} alt=""/>
                                        <h6>REBOBINADOS</h6>
                                    </div>
                                </div>
                                <div className="tarjeta-cuerpo">
                                    <p>
                                        Rebobinado de motores eléctricos de
                                        corriente alterna, corriente continua,
                                        generadores, servomotores, motores
                                        sincrónicos y demás máquinas eléctricas
                                        rotativas. Empleamos materiales de
                                        primera calidad, con el objetivo de
                                        preservar el correcto funcionamiento
                                        manteniendo las características
                                        originales de la máquina.
                                    </p>
                                </div>
                            </div>
                            <div className="tarjeta-servicio">
                                <div className="tarjeta-titulo">
                                    <div className="tarjeta-titulo-flex">
                                        <img src={icon2} alt=""/>
                                        <h6>MANTENIMIENTO</h6>
                                    </div>
                                </div>
                                <div className="tarjeta-cuerpo">
                                    <p>
                                        Realizamos servicios de mantenimiento
                                        integral para todo tipo de motores
                                        eléctricos, utilizando materiales de
                                        excelencia y realizando diagnósticos al
                                        cliente para evitar futuras fallas, con el
                                        fin de que nuestro servicio sea óptimo y
                                        perdurable en el tiempo. <br/>
                                        Asistimos paradas de planta a todo tipo de
                                        empresas, otorgando prioridad a trabajos
                                        que demanden urgencia para satisfacción
                                        del cliente y sus necesidades.
                                    </p>
                                </div>
                            </div>
                            <div className="tarjeta-servicio">
                                <div className="tarjeta-titulo">
                                    <div className="tarjeta-titulo-flex">
                                        <img src={icon3} alt=""/>
                                        <h6>VENTA DE MOTORES ELÉCTRICOS</h6>
                                    </div>
                                </div>
                                <div className="tarjeta-cuerpo">
                                        <ul style={{lineHeight:"30px",}}>
                                            <li>Venta de motores WEG.</li>
                                            <li>Distribuidores de repuestos WEG.</li>
                                            <li>Motores trifásicos de inducción.</li>
                                            <li>Motores antiexplosivos.</li>
                                            <li>Motores monofásicos.</li>
                                            <li>Motores con freno.</li>
                                        </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Servicios;
