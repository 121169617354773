import React from 'react';


//Components
import SliderPpal from "./Home/SliderPpal";
import Servicios from "./Home/Servicios";
import Galeria from "./Home/Galeria";
import SobreNosotros from "./Home/SobreNosotros";
import Clientes from "./Home/Clientes";
import Contacto from "./Home/Contacto";
import DatosContacto from "./Home/DatosContacto";

class Home extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div>
                <SliderPpal/>
                <Servicios/>
                <Galeria/>
                <SobreNosotros/>
                {/*<Clientes/>*/}
                <Contacto/>
                <DatosContacto/>
            </div>
        );
    }
}

export default Home;
