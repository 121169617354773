import React from 'react';
import "../../../assets/css/FormCotizacion.css";
import $ from "jquery";
//Components


//Img


class FormMantenimiento extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                nombre: "",
                email: "",
                telefono: "",
                servicio: "",
            },
            error: null,
            spinner: null

        };

        this.submitForm = this.submitForm.bind(this);
        this.changeForm = this.changeForm.bind(this);
    }

    componentDidMount() {
        this.setState({
            form: {
                servicio: this.props.servicio.nombre,
            }
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.servicio.nombre !== this.props.servicio.nombre) {
            this.setState({
                form: {
                    servicio: this.props.servicio.nombre,
                }
            });
        }

    }

    submitForm(e) {
        e.preventDefault();
        $.ajax({
            url: process.env.PUBLIC_URL + "/php/mantenimientoForm.php",
            type: 'POST',
            data: this.state.form,
            // dataType: 'json',
            dataType: 'html',
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            beforeSend: function (data) {
                this.setState({spinner: true});
            }.bind(this),
            cache: false,
            success: function () {
                // console.log("success");
                this.setState({error: false});
                this.setState({spinner: false});
            }.bind(this),
            // Fail..
            error: function (xhr, status, err) {
                // console.log(err);
                this.setState({error: true});
                this.setState({spinner: false});
            }.bind(this)
        });
    }

    changeForm(e) {
        var cambio = this.state.form;
        cambio[e.target.id] = e.target.value;
        this.setState({form: cambio});
    }

    render() {
        return (
            <div id={"cotizacion"} hidden={!this.props.hidden}>
                <section className="cotizacion">
                    <div className="modal">
                        <div className="cruz-cerrar" alt="cerrar" onClick={this.props.closeModal}>&times;</div>
                        <div className="modal-body">
                            <h4>Para solicitar información acerca de este servicio
                                por favor ingresá los siguientes datos. Te responderemos a la brevedad</h4>
                            <form onSubmit={(e) => this.submitForm(e)}>
                                <input id="nombre" required={true}
                                       onChange={(e) => this.changeForm(e)}
                                       type="text" placeholder="Nombre y Apellido"
                                       value={this.state.form.nombre ? this.state.form.nombre : ""}
                                />
                                <input id="email" required={true} onChange={(e) => this.changeForm(e)}
                                       type="email" placeholder="E-Mail"
                                       value={this.state.form.email ? this.state.form.email : ""}/>
                                <input id="telefono" required={true} onChange={(e) => this.changeForm(e)}
                                       type="text" placeholder="Telefono"
                                       value={this.state.form.telefono ? this.state.form.telefono : ""}
                                />
                                <textarea id="mensaje" required={true} onChange={(e) => this.changeForm(e)}
                                          type="text" placeholder="Mensaje"
                                          value={this.state.form.mensaje ? this.state.form.mensaje : ""}/>
                                <div className="btn-submit-container">
                                    {this.state.spinner ?
                                        <div className="lds-dual-ring white"></div>
                                        :
                                        <button type="submit">ENVIAR DATOS</button>
                                    }
                                </div>
                                <p className="mensaje"
                                   style={{display: this.state.error == false ? "block" : "none"}}>¡Mensaje enviado con
                                    éxito!</p>
                            </form>
                        </div>
                    </div>
                </section>
            </div>

        );
    }
}

export default FormMantenimiento;
