import React from 'react';
import "../../../assets/css/DatosContacto.css";

//Components
import GoogleMapReact from 'google-map-react';

//Img
import email from "../../../assets/images/redes/icon-email.png";
import marker from "../../../assets/images/redes/icon-marker.png";
import tel from "../../../assets/images/redes/icon-tel.png";
import markerMap from "../../../assets/images/marker.png";

const Marcador = () => {
    return (
        <div className="link-marker">
            <a href="https://goo.gl/maps/hTkayqx4EfxrC5pq8" target="_blank">
                <img src={markerMap}
                     alt="Fabrifor"
                     className="marker-map"/>
            </a>
            <p>Fabrifor</p>
        </div>
    );
};

class DatosContacto extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    componentWillUnmount() {

    }

    createMapOptions() {
        return {
            panControl: false,
            mapTypeControl: false,
            scrollwheel: this.props.scroll,
            styles: [
                {
                    "featureType": "all",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#eef0f2"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -20
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        },
                        {
                            "saturation": -60
                        }
                    ]
                }
            ],


        };
    }


    render() {
        var marcadores = [];
        marcadores.push(<Marcador lat={-32.923335} lng={-60.690387} key={1}/>);

        return (
            <div>
                <section className="datos-contacto">
                    <div className="container-limit">
                        <div className="container-dato">
                            <div className="dato">
                                <img src={email} alt=""/>
                                <div className="dsc-datos">
                                    <h6>E-MAIL</h6>
                                    <a href="mailto:contacto@fabrifor.com">contacto@fabrifor.com</a>
                                </div>
                            </div>
                            <div className="dato">
                                <img src={marker} alt=""/>
                                <div className="dsc-datos">
                                    <h6>DIRECCIÓN</h6>
                                    <a target="_blank" href="https://goo.gl/maps/hTkayqx4EfxrC5pq8">
                                        República dominicana 429 bis <br/>
                                        (Vélez Sarsfield al 1800)<br/>
                                        S2002NWA - Rosario (Santa Fe)
                                    </a>
                                </div>
                            </div>
                            <div className="dato">
                                <img src={tel} alt=""/>
                                <div className="dsc-datos">
                                    <h6>TELEFONO</h6>
                                    <a href="tel:03414419210">Tel: (0341) 441-9210</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="mapa">
                    <GoogleMapReact
                        bootstrapURLKeys={{
                            key: 'AIzaSyCoDe1Z0uT8qm3x0Z8OWdTiYN1w4jBAoHs',
                            language: 'es'
                        }}
                        center={{lat: -32.923335, lng: -60.690387}}
                        defaultCenter={{lat: -32.923335, lng: -60.690387}}
                        defaultZoom={17}
                        zoomControl={false}
                        gestureHandling="none"
                        options={this.createMapOptions.bind(this)}
                    >
                        {marcadores}
                    </GoogleMapReact>
                </section>
            </div>

        );
    }
}

export default DatosContacto;
