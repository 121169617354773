import React from 'react';
import "../../../assets/css/SliderPpal.css";
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/src/owl.carousel.css';
import history from "../../../history";

//Components


//Img
import slide1 from "../../../assets/images/slides/slide1.png";
import slide1webp from "../../../assets/images/slides/slide1.webp";
import slide2 from "../../../assets/images/slides/slide2.png";
import slide2webp from "../../../assets/images/slides/slide2.webp";
import slide3 from "../../../assets/images/slides/slide3.png";
import slide3webp from "../../../assets/images/slides/slide3.webp";
import slide4 from "../../../assets/images/slides/slide4.png";
import slide4webp from "../../../assets/images/slides/slide4.webp";

import wegLogo from "../../../assets/images/wegLogo.png";
import certificado from "../../../assets/images/certificado.png";


class SliderPpal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {showCertificado: false};
    }

    openCertificado() {
        this.setState({showCertificado: true})
        document.body.classList.add("no-scroll");
    }

    closeCertificado() {
        this.setState({showCertificado: false});
        document.body.classList.remove("no-scroll");
    }

    goToProductos() {
        history.push("/productos");
    }

    goToServicio() {
        document.getElementById("servicios").scrollIntoView({
            behavior: "smooth"
        });
    }

    render() {
        return (
            <div id={"home"}>
                <section className="slider-principal">
                    <OwlCarousel ref="car" options={{
                        items: 1,
                        nav: false,
                        rewind: true,
                        autoplay: true,
                        dots: true
                    }}>

                        <div className="item"
                             style={{backgroundImage: `-webkit-image-set(url(${slide1webp}) 1x), url(${slide1})`}}>
                            <div className="container-limit">
                                <div className="container-doble">
                                    <div className="col-6" style={{display: "flex", alignItems: "center"}}>
                                        <div className="container-asistente-tec">
                                            <h1>ASISTENTE TÉCNICO AUTORIZADO</h1>
                                            <div className="linea"></div>
                                            <h2>Distribuidor de motores y drives</h2>
                                        </div>
                                    </div>
                                    <div className="col-6"
                                         style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                                        <div className="container-logo-weg">
                                            <img src={wegLogo} alt=""/>
                                            <button onClick={() => this.openCertificado()} style={{float: "right"}}>VER
                                                CERTIFICACIÓN
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item"
                             style={{backgroundImage: `-webkit-image-set(url(${slide2webp}) 1x), url(${slide2})`}}>
                            <div className="container-limit">
                                <div className="container-portada">
                                    <div>
                                        <h2>COTIZACIÓN ONLINE DE PRODUCTOS</h2>
                                        <button style={{marginTop: "10px"}} onClick={() => this.goToProductos()}>VER
                                            PRODUCTOS
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item"
                             style={{backgroundImage: `-webkit-image-set(url(${slide3webp}) 1x), url(${slide3})`}}>
                            <div className="container-limit">
                                <div className="container-portada">
                                    <div>
                                        <h3>DESDE<b>&nbsp;1976<br/></b>BRINDANDO SERVICIOS ELECTROMECÁNICOS A LA
                                            INDUSTRIA</h3>
                                        <button style={{marginTop: "10px"}} onClick={() => this.goToServicio()}>NUESTROS
                                            SERVICIOS
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item"
                             style={{backgroundImage: `-webkit-image-set(url(${slide4webp}) 1x), url(${slide4})`}}>
                            <div className="container-limit">
                                <div className="container-portada">
                                    <div>
                                        <h2 style={{fontSize: "4rem", maxWidth: "525px"}}>CALIDAD Y TECNOLOGÍA AL
                                            SERVICIO DE <u>LA INDUSTRIA</u></h2>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                </section>
                <section className="certificado" style={{display: this.state.showCertificado ? "flex" : "none"}}>
                    <div className="img-certificado">
                        <div className="cruz-cerrar" alt="cerrar" onClick={() => this.closeCertificado()}>&times;</div>
                        <img src={certificado} alt=""/>
                    </div>
                </section>
            </div>
        );
    }
}

export default SliderPpal;
