import React from 'react';
import '../../../assets/css/Webinar.css';

export default function Webinar() {
    return (
        <div id="webinar">
            <section className="webinar">
                <div className="container-limit">
                    <div className="container-doble">
                        <div className="col-6">
                            <div className="container-webinar">
                                <div className="container-titulo">
                                    <h3>Webinar</h3>
                                </div>
                                <div className="container-text">
                                    <p>
                                        Casos de diagnóstico en Motores Eléctricos
                                    </p>
                                </div>
                                <button onClick={() => {
                                    window.open("https://www.youtube.com/watch?v=UEjrXhUcPrA&feature=emb_logo&ab_channel=IvanaRiveroArtecIngenier%C3%ADa", "blank")
                                }}>
                                    VER WEBINAR
                                </button>
                            </div>
                        </div>
                        <div className="col-6">
                            <div id={"container-webinar2"} className="container-webinar">
                                <div className="container-titulo">
                                    <h3>Webinar</h3>
                                </div>
                                <div className="container-text">
                                    <p>
                                        Preguntas y experiencias de
                                        usuarios
                                    </p>
                                </div>
                                <button onClick={() => {
                                    window.open("https://www.youtube.com/watch?v=rQYo7FUc9JM&feature=emb_logo&ab_channel=ArtecIngenier%C3%ADa", "blank")
                                }}>
                                    VER WEBINAR
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}