import React from 'react';

import foto1 from '../../../../assets/images/mantenimiento/plantas/controlCalidad/1_control_calidad.png';
import foto2 from '../../../../assets/images/mantenimiento/plantas/controlCalidad/2_control_calidad.png';
import foto3 from '../../../../assets/images/mantenimiento/plantas/controlCalidad/3_control_calidad.png';
import LightBox from "react-images";


export default function ItemsControlDeCalidad(props) {
    const descargarPDF = () => {
        window.open(`${process.env.PUBLIC_URL}/pdf/Diagnostico integral de aislamiento.pdf`, '_blank')
    }

    return <div className="container-limit">
        <div className="container-doble">
            <div className="col-6">
                <div className="container-info-planta">
                    <p className="titulo">
                        CONTROL DE CALIDAD DE REPARACIONES
                    </p>
                    <p className="cuerpo">
                        Nuestros trabajos de mantenimiento y rebobinado de máquinas eléctricas son ensayados bajo
                        criterios de normas internacionales (IEC, IEEE, EASA) a fin de maximizar la efectividad de los
                        controles de calidad efectuados sobre los trabajos realizados. Esto otorga tranquilidad al
                        cliente y lógicamente la posibilidad de otorgar períodos de garantía iguales a Los de motores
                        nuevos.
                    </p>
                    <div className="planta-container-btn">
                        <button onClick={descargarPDF}>
                            DESCARGAR PDF
                        </button>
                        <button onClick={props.openModalMantenimiento}>
                            REALICE SU CONSULTA
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="galeria-img">
                    <div className="img-principal"
                         onClick={(e) => props.onClick(e, {index: 0, lightboxNum: 3})}>
                        <img src={foto1} alt=""/>
                    </div>

                    <div className="img-thumb">
                        <div className="thumb"
                             onClick={(e) => props.onClick(e, {index: 1, lightboxNum: 3})}
                        >
                            <img src={foto2} alt=""/>
                        </div>
                        <div className="thumb"
                             onClick={(e) => props.onClick(e, {index: 2, lightboxNum: 3})}
                        >
                            <img src={foto3} alt=""/>
                        </div>
                        <LightBox images={[
                            {src: foto1},
                            {src: foto2},
                            {src: foto3},
                        ]}
                                  onClose={props.onClose}
                                  onClickPrev={props.onClickPrev}
                                  onClickNext={props.onClickNext}
                                  currentImage={props.currentImage}
                                  isOpen={props.open}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>;
}