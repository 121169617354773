import React from 'react';

import foto1 from '../../../../assets/images/mantenimiento/plantas/plantasIndustriales/1_plantas_industriales.png';
import foto2 from '../../../../assets/images/mantenimiento/plantas/plantasIndustriales/2_plantas_industriales.png';
import foto3 from '../../../../assets/images/mantenimiento/plantas/plantasIndustriales/3_plantas_industriales.png';
import LightBox from "react-images";


export default function ItemsPlantasIndustriales(props) {
    const descargarPDF = () => {
        window.open(`${process.env.PUBLIC_URL}/pdf/Diagnostico integral de aislamiento.pdf`, '_blank')
    }
    return <div className="container-limit">
        <div className="container-doble">
            <div className="col-6">
                <div className="container-info-planta">
                    <p className="titulo">
                        RUTINAS DE ENSAYOS EN PLANTAS INDUSTRIALES
                    </p>
                    <p className="cuerpo">
                        Como rutina de ensayos habituales para diagnóstico predictivo, numerosas plantas del país
                        contratan nuestros servicios de manera periódica, a fin de conocer la evolución del estado de
                        motores eléctricos y generadores en tensiones de hasta 6,6 kV y con esta información programar
                        paradas de planta basadas en la condición presente y futura de las máquinas. <br/>
                        Esto conlleva importantes ahorros económicos no sólo por La optimización de las paradas de
                        mantenimiento, sino también por la eliminación del lucro cesante debido a fallas intempestivas.
                    </p>
                    <div className="planta-container-btn">
                        <button onClick={descargarPDF}>
                            DESCARGAR PDF
                        </button>
                        <button onClick={props.openModalMantenimiento}>
                            REALICE SU CONSULTA
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="galeria-img">
                    <div className="img-principal"
                         onClick={(e) => props.onClick(e, {index: 0, lightboxNum: 2})}>
                        <img src={foto1} alt=""/>
                    </div>

                    <div className="img-thumb">
                        <div className="thumb"
                             onClick={(e) => props.onClick(e, {index: 1, lightboxNum: 2})}
                        >
                            <img src={foto2} alt=""/>
                        </div>
                        <div className="thumb"
                             onClick={(e) => props.onClick(e, {index: 2, lightboxNum: 2})}
                        >
                            <img src={foto3} alt=""/>
                        </div>
                        <LightBox images={[
                            {src: foto1},
                            {src: foto2},
                            {src: foto3},
                        ]}
                                  onClose={props.onClose}
                                  onClickPrev={props.onClickPrev}
                                  onClickNext={props.onClickNext}
                                  currentImage={props.currentImage}
                                  isOpen={props.open}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>;
}